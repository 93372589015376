const categoriesReducerDefaultState = {
  data: [
    { productcategoryName: "BOPET FILM / POLYESTER" },
    { productcategoryName: "BOPP FILM" },
    { productcategoryName: "CPP FILM" },
    { productcategoryName: "PET RESIN" },
  ],
  name: "",
};

const categoriesReducer = (state = categoriesReducerDefaultState, action) => {
  switch (action.type) {
    case "SET_CATEGORIES":
      return {
        ...state,
        data: action.data,
      };
    case "REMOVE_CATEGORIES":
      return {
        ...state,
        data: state.data.filter((data) => data !== action),
      };
    case "CLEAR_CATEGORIES":
      return {
        ...state,
        data: [],
        name: "",
      };
    default:
      return state;
  }
};

export default categoriesReducer;
