import React from "react";
import "../Home/Home.style.css";



export const AboutParagraph = () => {
  return (
    <React.Fragment>
      <div className="about-paragraph">Understanding today`s challenges in food and non-food packaging industries. Ocean Flex committed to offer
opportunities supports and service to manufacturer to promote their product in the market perfectly. To achieve that goal we partners and build relationship with those who are best in business. 

            </div>
            









    </React.Fragment>
  );
};
