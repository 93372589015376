import React, { useState } from "react";
import {
  Card,
  Button,
  CardColumns,
  Col,
  Row,
  Container,
  Form,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.style.css";
import whyChooseOne from "../../img/why-choose-icon-1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import RoomIcon from "@material-ui/icons/Room";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";
import { getSendNotificationEmail } from "../../services/subscriberService";

export const GetInTouch = (props) => {
  const [emailTo, setEmailTo] = useState("");
  const [nameTo, setNameTo] = useState("");
  const [phoneTo, setPoneTo] = useState("");
  const [countryTo, setCountryTo] = useState("");
  const [productTo, setProductTo] = useState("");
  const [messageTo, setMessageTo] = useState("");
  const [notificationStatus, setNotificationStatus] = useState(false);
  const sendNotificationEmailUI = async () => {
    if (emailTo !== "") {
      const result = await getSendNotificationEmail(
        "c5c8bbf4-3c12-43a3-8af9-9282b25d19ac",
        "ContactInfoCustomer",
        emailTo,
        nameTo,
        phoneTo,
        messageTo,
        countryTo,
        productTo
      );
      const resultInfoLeads = await getSendNotificationEmail(
        "c5c8bbf4-3c12-43a3-8af9-9282b25d19ac",
        "ContactInfoLeads",
        emailTo,
        nameTo,
        phoneTo,
        messageTo,
        countryTo,
        productTo
      );
      if (result) {
        setNotificationStatus(true);
        console.log("Successfully send email to customer. Thank you.");
      }
      if (resultInfoLeads) {
        console.log("Successfully send email to respective admin. Thank you.");
      }
    }
  };
  return (
    <React.Fragment>
      {notificationStatus ? (
        <div class="titleContactInfo">
          <i>
            Thanks for contacting to us. Our sales team will reach out to you
            soon...
          </i>
        </div>
      ) : (
        <Container className="get-in-touch-top-padding">
          <Row>
            <Col md="6" lg="6">
              <h3 className="contact-info-h3">
                Get answers and advices from professional consultants.
              </h3>

              <div className="contact-content clearfix">
                <RoomIcon className="icon" />

                <div className="contact-text">
                  <h4>Address</h4>
                  <p>
                    Shop No.1, Zam Zam Arcade, Nanded, Maharashtra, India -
                    431704
                  </p>
                </div>
              </div>

              <div className="contact-content clearfix">
                <PhoneAndroidIcon className="icon" />

                <div className="contact-text">
                  <h4>Phone</h4>
                  <p>
                    +91 7020313130 - India & Asia
                    <br />
                    +91 9106032971 - Russia & EU
                    <br /> +971 554569786 - UAE & MENA Region
                    <br />
                    +91 8605565622 - United States & Latin America{" "}
                  </p>
                </div>
              </div>

              <div className="contact-content clearfix">
                <EmailIcon className="icon" />

                <div className="contact-text">
                  <h4>Email</h4>
                  <p>
                    <a href="mailto:#">email@sitename.com</a>
                  </p>
                </div>
              </div>
            </Col>

            <Col md="6" lg="6">
              <div className="contact-area mb-40">
                <div className="contact">
                  <Form.Group>
                    <Form.Control
                      size="lg"
                      type="text"
                      placeholder="Name"
                      onChange={(e) => setNameTo(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                    <Form.Control
                      size="lg"
                      type="email"
                      placeholder="Email"
                      onChange={(e) => setEmailTo(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                    <Form.Control
                      size="lg"
                      type="tel"
                      placeholder="Phone"
                      onChange={(e) => setPoneTo(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                    <Form.Control
                      as="select"
                      size="lg"
                      onChange={(e) => setCountryTo(e.target.value)}
                    >
                      <option>Select Country</option>
                      <option> India</option>
                      <option> USA</option>
                      <option> Russia</option>
                      <option> UAE</option>
                      <option> Latin America</option>
                    </Form.Control>
                    <p className="help-block text-danger"></p>
                    <Form.Control
                      as="select"
                      size="lg"
                      onChange={(e) => setProductTo(e.target.value)}
                    >
                      <option>Type of Product</option>
                      <option> Transparent Barrier Film</option>
                      <option> Matellized Film</option>
                      <option> Release Film</option>
                      <option> Anti Fog</option>
                      <option> Anti Static Film</option>
                      <option> Milky White Film</option>
                      <option> Super Clear Film</option>
                      <option> Heat sealable Film</option>
                      <option> sealable peelable film</option>
                      <option> Amber color film</option>
                      <option> Opaque White Film</option>
                      <option> Resins & Chemicals</option>
                    </Form.Control>
                    <p className="help-block text-danger"></p>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      placeholder="Message"
                      onChange={(e) => setMessageTo(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                    <div className=" ">
                      <a
                        href="#"
                        className="btn-default  btn3"
                        onClick={() => sendNotificationEmailUI()}
                      >
                        SEND MESSAGE
                      </a>
                    </div>
                  </Form.Group>

                  <form
                    name="sentMessage"
                    id="contactForm"
                    novalidate=""
                  ></form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.1118997534923!2d77.32349661457965!3d19.146578487047453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd1d63691e43e75%3A0x3a11b0fc3e07ae4a!2sZam%20Zam%20Bangles%20Stores!5e0!3m2!1sen!2sin!4v1603653237071!5m2!1sen!2sin"
        width="100%"
        height="450"
        frameborder="0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
    </React.Fragment>
  );
};
