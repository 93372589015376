import React from "react";
import { SectionTitle } from "../Common/SectionTitle";
import { Container } from "react-bootstrap";
import { Carddemo } from "../Services/Carddemo";

export const Services = () => {
  return (
    <React.Fragment>
      <div className="section-top-padding"></div>
      <SectionTitle sectionheading="Services & Specialty Products" />
      <Container>
        <Carddemo />
      </Container>
    </React.Fragment>
  );
};
