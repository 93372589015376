import React from "react";
import { Card, Button, CardColumns, Col, Row, Container, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/Home.style.css";
import whyChooseOne from "../../img/why-choose-icon-1.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';




export const Footer = (props) => {
  return (
    <React.Fragment>
    
	{/* <div className="section-dark sec-padding">
      <Container>
	  	<Row>

		  <Col md="4" sm="12" className="colmargin clearfix margin-bottom">
            <h4 className="text-white less-mar3 font-weight-5">Contact Us</h4>
            <div className="clearfix"></div>
            <br />
            <address className="text-light">
            <strong className="text-white">Address:</strong> <br />
            No.28 - 63739 street lorem ipsum, <br />
            ipsum City, Country
            </address>
            <span className="text-light"><strong className="text-white">Phone:</strong> + 1 (234) 567 8901</span><br />
            <span className="text-light"><strong className="text-white">Email:</strong> support@yoursite.com </span><br />
            <span className="text-light"><strong className="text-white">Fax:</strong> + 1 (234) 567 8901</span>
            <ul class="footer-social-icons white left-align icons-plain text-center">
              <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
              <li><a href="#"><i className="fa fa-facebook"></i></a></li>
              <li><a className="active" href="#"><i className="fa fa-google-plus"></i></a></li>
              <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
              <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
            </ul>
          </Col>

		
      
          
          <Col md="4" sm="12" className="colmargin clearfix margin-bottom">
            <h4 className="text-white less-mar3 font-weight-5">About Us</h4>
            <div className="clearfix"></div>
            <br />
            <ul className="footer-quick-links-4 white">
              <li><a href="#"><i className="fa fa-angle-right"></i> Placerat bibendum</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Ullamcorper odio nec turpis</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Aliquam porttitor vestibulum ipsum</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Lobortis enim nec nisi</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Placerat bibendum</a></li>
            </ul>
          </Col>
        
          
		  <Col md="4" sm="12" className="colmargin clearfix margin-bottom">
            <h4 class="text-white less-mar3 font-weight-5">Quick Links</h4>
            <div class="clearfix"></div>
            <br />
            <ul class="footer-quick-links-4 white">
              <li><a href="#"><i className="fa fa-angle-right"></i> Placerat bibendum</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Ullamcorper odio nec turpis</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Aliquam porttitor vestibulum ipsum</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Lobortis enim nec nisi</a></li>
              <li><a href="#"><i className="fa fa-angle-right"></i> Placerat bibendum</a></li>
            </ul>
          </Col>

	
          
          
         
          
          
        </Row>
      </Container>
	 
    </div> */}

	<section className="sec-padding-6 section-medium-dark">
      <Container>
        <Row>
          <div className="fo-copyright-holder text-center"> Copyright © 2020 OFIFILMS All rights reserved. | Designed and Developed by PickupBiz </div>
        </Row>
      </Container>
    </section>



    </React.Fragment>
  );
};
