import React, { useState, useEffect } from "react";
import { ControlledCarousel } from "./ControlledCarousel";
import { SectionTitle } from "../Common/SectionTitle";
import { AboutParagraph } from "../Common/AboutParagraph";

import { Row, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ProductBox } from "../Common/ProductBox";
import { GetInTouch } from "../Common/GetInTouch";
import { Footer } from "../Common/Footer";
import { WhyChooseSection } from "../Common/WhyChooseSection";
import { SearchComponent } from "../Common/SearchComponent";
import { ProductOverview } from "../Common/ProductOverview";
import { useDispatch } from "react-redux";
import { setSelctedProduct } from "../../state/actions/products";
import { getHomeDisplayProducts } from "../../services/subscriberService";

export const Home = () => {
  const [homeDisplayProducts, setHomeDisplayProducts] = useState();
  const [show, setShow] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSelectedProduct = (product) => {
    dispatch(setSelctedProduct(product));
    history.push("/productdetail");
  };

  const getDisplayProducts = async () => {
    const products = await getHomeDisplayProducts(
      "c5c8bbf4-3c12-43a3-8af9-9282b25d19ac"
    );
    setHomeDisplayProducts(products.data[0]);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    getDisplayProducts();
  }, []);
  return (
    <React.Fragment>
      <ControlledCarousel />
      <div className="light-background">
        <div className="section-top-padding"></div>
        <SectionTitle sectionheading="Who We Are" />
        <Container>
          <AboutParagraph />
        </Container>
        <div className="section-bottom-padding"></div>
      </div>

      <div className="section-top-padding"></div>
      <SectionTitle sectionheading="Our Products" />
      <Container>
        <Row>
          {homeDisplayProducts &&
            homeDisplayProducts.map((product) => (
              <ProductBox
                productThumbnail={product.picture}
                productboxname={`${product.name
                  .replace(/&amp;/g, "&")
                  .substring(0, 20)} ${product.name.length > 20 ? "..." : ""}`}
                productboxdescription={
                  product.shortDescription &&
                  `${product.shortDescription
                    .replace(/&amp;/g, "&")
                    .substring(0, 200)} ${
                    product.shortDescription.length > 200 ? "..." : ""
                  }`
                }
                handleShow={handleShow}
                handleSelectedProduct={handleSelectedProduct}
                product={product}
              />
            ))}
        </Row>
      </Container>
      <div className="view-all-txt">
        <a href="/productlist">VIEW ALL </a>
      </div>
      <div className="section-bottom-padding"></div>

      <div className="light-background">
        <WhyChooseSection isTitle={true} />
      </div>

      <div className="section-top-padding"></div>
      <SectionTitle sectionheading="Talk To Us" />
      <GetInTouch />
    </React.Fragment>
  );
};
